import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Spring, animated, config } from 'react-spring'
import dslogo from "../images/Design-Science-Logo-Full-Resize-White.png"

const Header = ({ siteTitle }) => (
  <header>
    <Spring native config={{delay: 50, tension: 280, friction: 120}} from={{ opacity: 0, transform: 'translate3d(0,-20px,0)' }} to={{ opacity: 1, transform: 'translate3d(0,0,0)' }}>
		  	{styles => 
          <animated.div style={styles}>
            <h1>
              <Link to="/">
                <img className="header-logo" src={dslogo} alt="Design Science Digital"/>
              </Link>
            </h1>
          </animated.div>}
          </Spring>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
