import React from "react"
import { Link } from "gatsby"
import { Spring, animated, config } from 'react-spring'
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import video from "../images/Evolution-Comp5.mp4"
import mailIcon from "../images/mail-icon.png"

const IndexPage = () => (
  <Layout>
    <SEO title="Design Science Digital" keywords={[`design science`, `web application`, `digital product agency`]} />
      
      <nav>
      <Spring native config={{delay: 450, tension: 280, friction: 120}} from={{ opacity: 0, transform: 'translate3d(0,-20px,0)' }} to={{ opacity: 1, transform: 'translate3d(0,0,0)' }}>
		  	{styles => 
          <animated.div style={styles}>
          <span><img className="mail-icon" src={mailIcon} alt="Email Us"/><a href="mailto:hello@designscience.co">hello@designscience.co</a></span>
          </animated.div>}
        </Spring>
      </nav>
    

    <div className="main-container">
    <Spring native config={{delay: 250, tension: 280, friction: 120}} from={{ opacity: 0, transform: 'translate3d(0,20px,0)' }} to={{ opacity: 1, transform: 'translate3d(0,0,0)' }}>
		  	{styles => 
          <animated.div style={styles}> 
          <h1 className="main-heading">We're undergoing an <br/> evolution of sorts.</h1>
          </animated.div>}
      </Spring>

      <Spring native config={{delay: 350, tension: 280, friction: 120}} from={{ opacity: 0, transform: 'translate3d(0,20px,0)' }} to={{ opacity: 1, transform: 'translate3d(0,0,0)' }}>
		  	{styles => 
        <animated.div className="hor-title-line" style={styles}></animated.div>}
      </Spring>
      
      <Spring native config={{delay: 450, tension: 280, friction: 120}} from={{ opacity: 0, transform: 'translate3d(0,20px,0)' }} to={{ opacity: 1, transform: 'translate3d(0,0,0)' }}>
		  	{styles => 
          <animated.div style={styles}>   
            <p className="main-sub-text">We’re transitioning from a generalist digital agency to
              one focused on building digital products and software. We’ll be relaunching our website soon. 
            </p>
          </animated.div> }
      </Spring>

      {/*<br/>
      <br/>
      <Spring native config={{delay: 650, tension: 280, friction: 120}} from={{ opacity: 0, transform: 'translate3d(0,20px,0)' }} to={{ opacity: 1, transform: 'translate3d(0,0,0)' }}>
		  	{styles =>
        <animated.div style={styles}>
          <button className="ds-button">But Since You're Here, View Our Work</button>
        </animated.div>}
        </Spring>*/}

    </div>

    <div className="media-container">
    <Spring native config={{delay: 650, tension: 280, friction: 120}} from={{ opacity: 0, transform: 'translate3d(0,20px,0)' }} to={{ opacity: 1, transform: 'translate3d(0,0,0)' }}>
		  {styles =>
        <animated.div style={styles}>
          <video src={video} autoPlay={true} loop muted playsInline />
        </animated.div>}
      </Spring>
    </div>

  </Layout>
)

export default IndexPage
